<template>
  <section id="surgery-files-container">
    <div class="header">
      <h4>Arquivos da cirurgia</h4>
      <span v-if="surgeryId && patientId" v-b-modal.surgery-files-modal>
        <Clip class="icon" />
        Anexar arquivos
      </span>
    </div>

    <hr>

    <Files
      :patientId="patientId"
      :surgeryInformationId="surgeryId"
      :generalSheetId="generalSheetId"
      :reloadFiles="onAttachFile"
      :needReloadFiles="needReloadFiles"
      @reload-files="onAttachFile"
    />

    <SurgeryFilesModal 
      v-if="surgeryId && patientId" 
      :surgeryInformationId="surgeryId" 
      :patientId="patientId"
      :generalSheetId="generalSheetId"
      @reload-files="onAttachFile"
    /> 
  </section>
</template>

<script>
export default {
  name: 'SurgeryFiles',
  components: {
    Clip: () => import('@/assets/icons/clip.svg'),
    SurgeryFilesModal: () => import('@/components/SurgeryCenter/SurgeryInformation/Modal/SurgeryFilesModal.vue'),
    Files: () => import('@/layouts/SurgeryCenter/SurgeryInformation/Files'),
  },
  props: {
    surgeryId: String,
    patientId: String,
    generalSheetId: String
  },
  data: () => ({
    selectedFile: null,
    needReloadFiles: false
  }),
  methods: {
    onAttachFile () {
      this.needReloadFiles = true
      this.$emit('reload-files')
    }
  }
}
</script>

<style lang="scss">
#surgery-files-container {
  background-color: var(--neutral-000);
  border-radius: 4px;
  padding: 16px;
  margin: 16px 0;

  h4 { 
    font-size: 1.1rem;
    font-weight: 700;
    color: var(--dark-blue);
  }

  hr { margin: 0 !important; }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 0;
    
    .icon {
      width: 24px;
      height: 24px;
      transform: scale(0.9);
    }

    span {
      color: var(--blue-500);
      cursor: pointer
    }
  }
}
</style>